<template>
    <b-card>
        <b-card-title
            class="mb-5"
            title-tag="h1"
        >
            {{ $t('auth.password.forgot.title') }}
        </b-card-title>

        <b-form
            v-if="! form.success"
            @submit.prevent="onSubmit"
        >
            <b-form-group
                :invalid-feedback="form.errors.get('email')"
                :label="$t('inputs.email.label')"
                :state="! form.errors.has('email')"
            >
                <b-form-input
                    v-model="form.data.email"
                    :placeholder="$t('auth.inputs.email.placeholder')"
                    type="email"
                />
            </b-form-group>

            <div class="d-flex">
                <b-btn
                    class="w-50 mr-2"
                    variant="light"
                    @click="$router.go(-1)"
                >
                    {{ $t('buttons.cancel') }}
                </b-btn>

                <btn-request
                    class="w-50"
                    :request="form"
                    type="submit"
                    variant="primary"
                >
                    {{ $t('auth.password.forgot.actions.request') }}
                </btn-request>
            </div>
        </b-form>

        <template v-else>
            {{ $t('auth.password.forgot.success') }}

            <b-button
                v-t="'auth.password.forgot.actions.backToLogin'"
                block
                class="mt-5"
                :to="{ name: 'auth.login' }"
                variant="primary"
            />
        </template>
    </b-card>
</template>

<script>
import Form from '@/library/http/Form';
import RecordsErrorMessage from '@/components/common/mixins/RecordsErrorMessage';

export default {
    name: 'Forgot',
    mixins: [RecordsErrorMessage],
    data() {
        return {
            form: new Form({
                email: '',
            }),
        };
    },
    methods: {
        async onSubmit() {
            try {
                await this.form.post('/auth/password/forgot');
            } catch (e) {
                this.recordErrorMessage(e, 'email');

                return;
            }
        },
    },
};
</script>
